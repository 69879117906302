import { NextSeo, NextSeoProps } from 'next-seo'
import { siteSettings } from '@settings/site-settings'

interface SeoProps extends NextSeoProps {
  path: string
  images?: any[]
}

const Seo = ({
  title,
  description,
  path,
  images = [
    {
      url: '/assets/images/og_fsd_can_v2.png',
      width: 858,
      height: 443,
      alt: 'FoodServiceDirect.ca',
    },
  ],
  canonical,
}: SeoProps) => {
  return (
    <NextSeo
      title={title}
      description={description}
      openGraph={{
        url: `${process.env.NEXT_PUBLIC_WEBSITE_URL}/${path}`,
        title,
        description,
        images,
      }}
      canonical={
        canonical ? siteSettings.base_url + canonical : siteSettings.canonical
      }
    />
  )
}

export default Seo
