import Seo from '@components/seo/seo'
import Divider from '@components/ui/divider'
import { GetStaticProps } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'

import Image from '@components/ui/image'
import Logo from '@components/ui/logo'
import { siteSettings } from '@settings/site-settings'
import cn from 'classnames'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { GoCheckCircleFill } from 'react-icons/go'

const data = {
  title: 'coming-soon-heading',
  description: 'coming-soon-description',
  appBG: '/assets/images/comingsoon-bg.png',
  appImage: '/assets/images/mac-logo-v2.png',
  pageListItems: [
    {
      id: 1,
      altText: 'coming-soon-ondemand',
    },
    {
      id: 2,
      altText: 'coming-soon-convenience',
    },
    {
      id: 3,
      altText: 'coming-soon-endless',
    },
  ],
}

export default function ComingSoon({ variant = 'default' }) {
  const { pageListItems, title, description, appImage, appBG } = data
  const { t } = useTranslation('common')
  const { site_header } = siteSettings
  const options = site_header.languageMenu
  const router = useRouter()
  const { asPath, locale } = router
  const currentSelectedItem = locale
    ? options.find((o) => o.value === locale)!
    : options[0]
  const [selectedItem, setSelectedItem] = useState(currentSelectedItem)

  function handleItemClick(values: any) {
    setSelectedItem(values)
    router.push(asPath, undefined, {
      locale: values.value,
    })
  }
  const year = new Date().getFullYear()
  return (
    <>
      <Seo
        title="Coming Soon"
        description="FoodServiceDirect is your source for restaurant supplies, dry foods, refrigerated & frozen foods, paper products, and all other restaurant needs including disposable & janitorial products."
        path="coming-soon"
      />
      <div
        className={cn('overflow-hidden bg-cover  bg-top')}
        style={{
          backgroundImage: `url(${appBG})`,
          backgroundSize: '100% 100%',
          backgroundPosition: 'top',
          height: '1900px',
        }}
      >
        <div className="text-center pt-6 md:pt-12 text-[12px] md:text-[16px] font-semibold">
          {options?.map((option, i) => (
            <button
              key={i}
              onClick={() => handleItemClick(option)}
              className={`${
                selectedItem?.name === option?.name
                  ? 'bg-brand text-brand-light'
                  : 'bg-brand-light text-brand'
              }  py-1.5 md:py-2.5 px-4 md:px-6 `}
              style={{ borderRadius: i === 0 ? '8px 0 0 8px' : '0 8px 8px 0' }}
            >
              {t(option.name)}
            </button>
          ))}
        </div>
        <div
          className={cn(
            'max-w-[1920px] w-44 pt-6 xl:pt-10 px-4 sm:px-5 md:px-6 lg:px-16 xl:px-28 2xl:px-32',
          )}
        >
          <Logo className="w-44 pointer-events-none" />
        </div>
        <div
          className={cn(
            'flex flex-col-reverse md:flex-row items-start justify-between max-w-[1920px] mx-auto px-4 sm:px-5 md:px-6 lg:px-16 xl:px-28 2xl:px-32',
          )}
        >
          <div className="shrink-0 mx-auto md:ltr:ml-0 md:rtl:mr-0 lg:flex lg:items-center pb-5 pt-1.5 md:pt-4 max-w-[350px] md:max-w-[340px] lg:max-w-[485px] xl:max-w-[540px] 2xl:max-w-[680px] 3xl:ltr:pl-10 3xl:rtl:pr-10 ltr:pr-0  xl:ltr:pr-8  2xl:ltr:pr-20">
            <div className="py-4 mb-1  md:rtl:text-right">
              <h2 className="text-[32px] md:text-3xl  xl:text-4xl 2xl:text-[52px] leading-[1.4em] xl:leading-[1.45em] text-brand-dark font-extrabold font-manrope -tracking-[0.2px] mb-3 lg:mb-4">
                {t(title)}
              </h2>
              <p
                className="text-15px xl:text-base 2xl:text-[16px] leading-7 xl:leading-7 text-brand-muted text-opacity-70 pb-5 lg:pb-7 
                   xl:rtl:pl-8 rtl:pl-0 2xl:rtl:pl-20"
              >
                {t(description)}
              </p>
              <div className="flex flex-wrap">
                {[...pageListItems.slice(0, 2)]?.map((item) => (
                  <div
                    key={item.id}
                    className="bg-brand whitespace-nowrap flex items-center p-3.5 text text-brand-light rounded-md mr-5 my-2 sm:my-3"
                  >
                    <GoCheckCircleFill fontSize={20} className="mr-2" />
                    {t(item.altText)}
                  </div>
                ))}
              </div>
              <div className="inline-flex bg-brand whitespace-nowrap flex items-center p-3.5 text text-brand-light rounded-md  my-2 sm:my-3">
                <GoCheckCircleFill fontSize={20} className="mr-2" />
                {t(pageListItems[2].altText)}
              </div>
            </div>
          </div>
          <div className="flex-row-reverse md:flex items-center p-4 pb-0 sm:pt-16 rtl:pr-4 2xl:ltr:pl-0 2xl:rtl:pr-0 md:max-w-[450px] lg:max-w-[660px] xl:max-w-auto  rtl:-ml-10  lg:rtl:-ml-16  xl:rtl:-ml-10 3xl:ltr:mr-7 3xl:rtl:ml-7">
            <Image
              src={appImage}
              alt={t('text-mac-thumbnail')}
              // className="sm:-mt-40 sm:-mt-80"
              className=""
              width={660}
              height={465}
              quality={100}
              style={{ width: '660px' }}
            />
          </div>
        </div>
      </div>
      <div className="text-center pb-5 lg:pb-7">
        <div
          className={`${
            variant === 'default' &&
            'mx-auto max-w-[1920px] px-4 md:px-6 lg:px-8 2xl:px-10'
          }`}
        >
          <div className="flex flex-col pt-6 text-center border-t md:flex-row md:justify-center border-border-three lg:pt-7">
            <p className="text-brand-dark text-sm leading-7 lg:leading-[27px] lg:text-15px">
              &copy;&nbsp;{t('text-copyright')} {year}&nbsp;
              <a
                className="transition-colors duration-200 ease-in-out text-brand-dark hover:text-brand"
                href={siteSettings.author.websiteUrl}
              >
                {siteSettings.author.name}
              </a>
              &nbsp; {t('text-all-rights-reserved')}
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
