import Image from 'next/image'
import Link from '@components/ui/link'
import cn from 'classnames'
import { siteSettings } from '@settings/site-settings'
import { AnchorHTMLAttributes, FC } from 'react'

const Logo: FC<AnchorHTMLAttributes<{}>> = ({
  className,
  href = siteSettings.logo.href,
  ...props
}) => {
  return (
    <Link
      href={href}
      className={cn(
        'inline-flex focus:outline-none w-auto md:w-32 lg:w-72',
        className,
      )}
      {...props}
    >
      <Image
        src={siteSettings.logo.url}
        alt={siteSettings.logo.alt}
        priority
        loading="eager"
        style={{ width: '80%' }}
      />
    </Link>
  )
}

export default Logo
