import { QueryOptionsType, Product } from '@framework/types'
import { useQuery } from 'react-query'
import * as typesense from '../typesense'
import { useRouter } from 'next/router'

export const fetchTrendingProducts = async ({ queryKey }: any) => {
  const [_key, _params, locale] = queryKey
  const { hits }: any = await typesense.getTrendingProducts(locale)
  const products: Product[] = []
  hits.map((h: any) => {
    if (h.document.name && h.document.description) {
      products.push(h.document)
    }
  })

  return products as Product[]
}

export const useTrendingProductsQuery = (options: QueryOptionsType) => {
  return useQuery<Product[], Error>(
    ['trendingProducts', options, useRouter().locale],
    fetchTrendingProducts,
  )
}
